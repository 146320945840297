import React from 'react'

import {Paper, Input, Button, Typography, withStyles, Theme, createStyles, WithStyles} from '@material-ui/core'


const styles = (theme: Theme)  => createStyles({
  input: {
    fontFamily: theme.typography.monoFontFamily,
  },
})

interface Props extends WithStyles<typeof styles> {
  code: string,
}

declare global {
  interface Navigator {
    share: (data: {url?: string, text?: string, title?: string})=>Promise<undefined>
  }
}

const CopyCode = ({code, classes}: Props) => {

  let inputRef = React.createRef<HTMLInputElement>()
  // From https://hackernoon.com/copying-text-to-clipboard-with-javascript-df4d4988697f

  const share = () => {
    if (navigator.share) {
      navigator.share({url, title: document.title})
    }
  }

  const shareable = Boolean(navigator.share)

  const copyToClipboard = () => {
    let selection : Selection | null
    selection = document.getSelection()
    let selected: Range | null = null

    if (selection !== null) {
      selected = selection.rangeCount > 0        // Check if there is any content selected previously
        ? selection.getRangeAt(0)     // Store selection if found
        // eslint-disable-next-line semi 
        : null; // Mark as false to know no selection existed before  (semicolon to not confuse VSCode highligher)
    }

    if (inputRef.current) {
      inputRef.current.select()                                    // Select the <textarea> content 
      document.execCommand('copy')                   // Copy - only works as a result of a user action (e.g. click events)
    }


    if (selection !== null) {
      selection.removeAllRanges()    // Unselect everything on the HTML document
      if (selected) {                                 // If a selection existed before copying
        selection.addRange(selected)   // Restore the original selection
      }
    }
  }

  const url = `${process.env.PUBLIC_URL}/${code}` // eslint-disable-line no-undef

  React.useEffect(()=> {
    if (!inputRef.current) {
      return
    }
    inputRef.current.scrollLeft = inputRef.current.scrollWidth
  }, [inputRef, url])
  return <Paper className="copy-code">
    <Typography> Share URL: </Typography>
    <Input className={classes.input} readOnly value={url} inputRef={inputRef}/>
    <Button onClick={copyToClipboard}>Copy</Button>
    {shareable && <Button onClick={share}>Share</Button>}

  </Paper>
}

export default withStyles(styles)(CopyCode)