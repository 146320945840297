import React from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core/styles'

import theme from './api/theme'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker' 

const Root = ()=> <MuiThemeProvider theme={theme}>
  <App/>
</MuiThemeProvider>
    
ReactDOM.render(<Root />, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
