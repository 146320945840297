import {content, ADVANCED_THRESHOLD} from 'roomboom-content'
import { IGameConfig } from "../game-config";


export default ({playerCount, advanced} : IGameConfig ) => {
  const countCutoffs = Object.keys(content).map(i=>Number(i))

  const relevantCutoff = countCutoffs.find(cutoff=>playerCount <= cutoff)

  if (!relevantCutoff) {
    throw new Error('Unable to find round data')
  }

  const info = content[relevantCutoff]
  
  if (!advanced) {
    return info.filter(({len})=> len <= ADVANCED_THRESHOLD)
  }

  return info
}