import React from 'react'
import {Button} from '@material-ui/core'
import { useAuthState } from 'react-firebase-hooks/auth'
import firebase from 'firebase/app'
import { CssBaseline, Input, withStyles, Paper, Theme, WithStyles,createStyles } from '@material-ui/core'

import { auth, firestore } from './api/firebase'
import MuteContext from './api/context/mute'
import getId, {POSSIBLE} from './util/get-id'
import usePath from './util/use-path'
import Game from './game'


declare global {
  interface Window {cast : any}
}

const styles = (theme:Theme) => createStyles({
  root: {
    textAlign: 'center',
    marginLeft: '10px',
    marginRight: '10px',
    marginTop: '10px',
  },
  optionsPaper: {
    'margin-top': '5px',
    display: 'flex',
    'justify-content': 'space-around', 
  },
  castContainer: {
    width: '25px',
  },
  castButton: {
    '--connected-color': theme.palette.background.default,

  },
})

const filterRe = new RegExp(`[^${POSSIBLE}]`, 'g')

const updateCast = (id:string | null) => {
  const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
  if (session) {
    session.sendMessage('urn:x-cast:com.hillshum.roomboom.code-change', {id})
  }
}


const App = ({classes}: WithStyles )=> {

  const { user } = useAuthState(auth)

  const [backMessage, setBackMessage] = React.useState('')
  const [isMuted, setMuted] = React.useState(false)
  const [gameId, changeCode] = React.useState<string | null>(null)

  const [gamePath, setPath] = usePath()

  const onCodeChange = (code: string) =>{
    setBackMessage('')
    changeCode(code.toUpperCase().replace(filterRe, ''))
  }

  React.useEffect(
    ()=>{ auth.signInAnonymously()},
    [user],
  )

  const newGame = () => {
    const id = getId()
    const collection = firestore.collection('games')
    const newGame = { 
      playerCount: 10,
      round: 0,
      advanced: false,
      createdAt: firebase.firestore.Timestamp.now(),
    }

    collection.doc(id).set(newGame).then(()=>{
      setPath(id)
      updateCast(id)
    })

  }

  const goBack = (message='') => {

    changeCode('')
    setPath('')
    updateCast('')
    setBackMessage(message)
  }

  const startGame = ()=>{
    setPath(gameId || '')
    updateCast(gameId)
  }

  return (
    <MuteContext.Provider value={isMuted}>
      <CssBaseline/>
      <div className={classes.root}>
        {!gamePath && <div className="timer-picker">
          <Input
            type="text"
            value={gameId || ''}
            placeholder="Enter a game ID"
            onChange={({target: {value}})=>onCodeChange(value)}
          />
          <Button disabled={!gameId} onClick={startGame}>Submit</Button>
          <Button onClick={newGame}>Create New</Button>
          {backMessage && <div className="back-message">{backMessage}</div>}
        </div>}
        {user && gamePath && <Game gameId={gamePath} goBack={goBack} />}
        <Paper className={classes.optionsPaper}>
          <Button onClick={()=>setMuted(!isMuted)}>{isMuted ? 'Unmute' : 'Mute'} </Button>
          <div className={classes.castContainer}>
            <google-cast-launcher class={classes.castButton}></google-cast-launcher>
          </div>
        </Paper>
      </div>
    </MuteContext.Provider>
  )
}

export default withStyles(styles)(App)
