import React from 'react'



declare global {
  interface Navigator {getWakeLock : any}
}


const useWakeLock = (shouldLock: boolean) => {
  const [request, setRequest] = React.useState()
  const [lock, setLock] = React.useState()
  const [isLocked, setLocked] = React.useState(false)

  React.useEffect(()=>{
     if ('getWakeLock' in navigator) {
       navigator.getWakeLock('screen').then(setLock).catch((e: Error)=> console.log('Unable to get wake lock', e))
    }
  }, [])

  React.useEffect(()=>{
    if (shouldLock && lock && !request) {
      setRequest(lock.createRequest())
      setLocked(true)
    }
    
    if (!shouldLock && request) {
      request.cancel();
      setRequest(null)
      setLocked(false)
    }

    return ()=> {
      if (isLocked) {
        request.cancel()
      }
    }
  }, [shouldLock, request, lock])

  React.useEffect(()=>{
    if (!lock) return
    lock.addEventListener('activeChange', ()=> {
      setLocked(lock.active)
    })
    //TODO: Return cleanup
  }, [lock])

  return isLocked
}

export default useWakeLock