import React from 'react'
import moment from 'moment'

import {Theme, Typography, withStyles, createStyles, WithStyles } from '@material-ui/core'

const styles = (theme : Theme) => createStyles({
  root: {
    color: theme.palette.text.secondary,
    fontSize: '10rem',
  },
})

const getDurString = (dur: moment.Duration)  => {
  let seconds = String(dur.seconds())
  seconds = Number(seconds) < 10 ? `0${seconds}` : seconds

  return `${dur.minutes()}:${seconds}`
}

interface Props extends WithStyles<typeof styles> {
  remaining: number
}

const TimeView = ({remaining, classes}: Props) => {
  const dur = moment.duration(remaining)

  return <div>
    <Typography classes={{root: classes.root}}color="textSecondary">
      {getDurString(dur)}
    </Typography>
  </div>
}

export default withStyles(styles)(TimeView)