import React from 'react'
import classnames from 'classnames'

import {Button, withStyles, Theme, createStyles, WithStyles } from '@material-ui/core'

import alarm from '../alarm.mp3'
import TimeView from './time-view'

import firebase from 'firebase/app' 

import MuteContext from '../api/context/mute'

type Timestamp = firebase.firestore.Timestamp

const getDelta = (target:Timestamp) => Math.max(target.toMillis() - Date.now(), 0)

const styles = (theme: Theme) => createStyles({
  blinking: {
    animation: 'blinkingText 1.5s infinite',
  },
  '@keyframes blinkingText': {
    '0%': {opacity: 1},
    '50%': {opacity: 0},
    '100%': {opacity: 1},
  },
})

interface Props extends WithStyles{
  target: any,
  onFinish: ()=>any,
  onPause: ()=>any,
}


const DurationView = ({target, onFinish, onPause, classes} : Props)=> {
  const [delta, updateDelta] = React.useState(getDelta(target))
  const [didVibrate, setVibrate] = React.useState(false)
  const isMuted = React.useContext(MuteContext)

  React.useEffect(
    ()=>{
      const timer = window.setInterval(()=>{
        updateDelta(getDelta(target))
      }, 500)
      return ()=> {
        window.clearInterval(timer)
      }
    },
    [target],
  )
  const expired = delta <= 0

  if (expired && !didVibrate) {
    navigator.vibrate([500, 200, 500, 200, 500])
    setVibrate(true)
  }

  if (!expired && didVibrate) {
    setVibrate(false)
  }


  return <div className="duration-view">
    <div className={classnames({[classes.blinking]: expired})}>
      <TimeView remaining={delta}/>
    </div>
    {!expired && <Button onClick={onPause}>Pause</Button>}
    { expired && <div className="expired">
      {! isMuted && <audio src={alarm} autoPlay/>}
      <Button onClick={onFinish}>Begin Next Round</Button>
    </div>
    }
  </div>
}

export default withStyles(styles)( DurationView)