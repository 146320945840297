import _slicedToArray from "/home/hillshum/Code/roomboom/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";
import content from './roundInfo';
var ADVANCED_THRESHOLD = 3;
var advancedCutoff = Number(Object.entries(content).filter(function (_ref) {
  var _ref2 = _slicedToArray(_ref, 2),
      maxPlayers = _ref2[0],
      roundArr = _ref2[1];

  return roundArr.length < 5;
}).sort(function (a, b) {
  return Number(a[0]) + Number(b)[0];
})[0][0]); // get the highest one

var CUTOFFS = [[6, 10], [11, 13], [14, 17], [18, 21], [22, 5000]];
export { content, advancedCutoff, ADVANCED_THRESHOLD, CUTOFFS };