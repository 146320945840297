import React from 'react'

import { useDocument } from 'react-firebase-hooks/firestore'
import firebase from 'firebase/app'
import { Paper, withStyles, createStyles, WithStyles } from '@material-ui/core'

import Timer from './timer'
import CopyCode from './components/copy-code'
import { firestore } from './api/firebase'
import GameConfig, {IGameConfig} from './game-config'
import { Button } from '@material-ui/core'
import getRoundDetails from './util/getGameInfo'
import RoundInfo from './components/round-info'

const styles = () => createStyles({
  smallMargin: {
    marginTop: '10px',
    marginBottom: '10px',
  },
})

interface Props extends WithStyles {
  gameId: string,
  goBack: (msg?: string)=>any,
}

interface GameSettings {
  advanced: boolean,
  playerCount: number,
  round: number,
  timerId?: string,
}


const Game = ({gameId, goBack, classes}: Props) => {

  const collection = firestore.collection('games')

  const {error, loading, value } = useDocument(collection.doc(gameId))

  if (error) {
    // console.error(error)
    return <div>'Something broke'</div>
  }

  if (loading || !value) {
    return <div>'Loading'</div>
  }

  if (!value.exists) {
    goBack(`Game ${gameId} not found`)
    return null
  }

  const onConfigChange = (config: IGameConfig)=>{
    value.ref.set(config, {merge: true})
  }

  const {playerCount, round, timerId, advanced} = value.data() as GameSettings

  const gameInfo = getRoundDetails({playerCount, advanced})

  const nextRound = async () => {
    const newRound = round + 1
    
    value.ref.set({round: newRound}, {merge: true})

    if (newRound > gameInfo.length) {
      return
    }

    const newLength = gameInfo[newRound - 1].len
    const millis = Number(new Date()) + (newLength * 1000 * 60)

    const newTimer = {
      target: firebase.firestore.Timestamp.fromMillis(millis),
      createdAt: firebase.firestore.Timestamp.now(),
    }

    if (!timerId) {
      const {id} = await firestore.collection('timers').add(newTimer)
      value.ref.set({timerId: id}, {merge: true})
    } else {
      firestore.collection('timers').doc(timerId).set(newTimer)
    }

  }

  const newGame = ()=>{
    value.ref.set({round: 0}, {merge: true})
  }

  const showTimer = (round > 0) && round <= gameInfo.length 

  return <div className="game-screen">
    <CopyCode code={gameId}/>
    <Paper className={classes.smallMargin}>

      {round === 0 && 
      <GameConfig
        config={{playerCount, advanced}} 
        onChange={onConfigChange}
        nextRound={nextRound}
      /> }
      
      {showTimer && timerId && <Timer timerId={timerId} advanceRound={nextRound}/>}

      {round > 0 && <RoundInfo gameInfo={gameInfo} round={round} />}
    </Paper>
    <Paper>
      <Button onClick={newGame}>{round > gameInfo.length ? 'New Game' : 'End Game'}</Button>
      <Button onClick={()=>goBack()}>Leave Game</Button>
    </Paper>


  </div>
}


export default withStyles(styles)(Game)