import React from 'react'
import PropTypes from 'prop-types'
import { 
  Button, Radio, RadioGroup, FormControlLabel, FormControl, withStyles, WithStyles, createStyles
 } from '@material-ui/core'

import {advancedCutoff, CUTOFFS} from 'roomboom-content'
import ArrowEdit from './components/arrow-edit'

interface Props  extends WithStyles{
  config: IGameConfig,
  onChange: (config: IGameConfig)=> any,
  nextRound: React.MouseEventHandler,

}

export interface IGameConfig{
  playerCount: number
  advanced: boolean
}

const styles = ()=> createStyles({
  root: {
    display: 'flex',
    "flex-direction": 'column',
    'align-items': 'center',
  },
  spacing: {
    'margin-top': '20px',
  }
})



        // <ArrowEdit val={playerCount} onChange={val=>onChange({playerCount: val, advanced})}/>
const GameConfig = ({config: {playerCount, advanced}, onChange, nextRound, classes} : Props) => {
  const isAdvancedAllowed = playerCount > advancedCutoff

  const handleCountChange = (e: React.ChangeEvent<{}>, value: string, ) => {
    onChange({advanced, playerCount: Number(value)})


  }
  const advancedStr =  isAdvancedAllowed ?  String(advanced) : 'false' 
  return <div className={`game-config ${classes.root}`}>
    <FormControl> 
      <FormControlLabel label="Player Count"
      control={<RadioGroup value={String(playerCount)} onChange={handleCountChange}>
        {CUTOFFS.map(([startCount, endCount])=>(
          <FormControlLabel key={startCount} label={`${startCount} - ${endCount}`} value={String(endCount)} control={<Radio/>}/>
        ))}
      </RadioGroup>}
      />
    </FormControl>
    <FormControl className={classes.spacing}> 
      <FormControlLabel label="Game Length"
        control={ 
        <RadioGroup value={advancedStr}
          onChange={()=>onChange({playerCount, advanced: !advanced})}>
          <FormControlLabel
            value="true"
            control={ <Radio disabled={!isAdvancedAllowed} />}
            label="5 rounds"
            />
          <FormControlLabel
            value="false"
            control={ <Radio disabled={!isAdvancedAllowed} />}
            label="3 rounds"
            />
        </RadioGroup>}
      />
      </FormControl>
    <FormControl>
      <Button onClick={nextRound}>Start</Button>
    </FormControl>
  </div>
}


export default withStyles(styles)(GameConfig)
