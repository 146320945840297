import React from 'react'
import {useDocument} from 'react-firebase-hooks/firestore'
import {Button } from '@material-ui/core'

import firebase from 'firebase/app' 

import DurationView from './components/duration-view'
import TimeView from './components/time-view'
import Loading  from './components/loading'
import { firestore } from './api/firebase'

import useWakeLock from './util/use-wakelock'

interface Props {
  timerId: string,
  advanceRound: ()=>any,
}


const Timer = ({timerId, advanceRound} : Props)=> {

  const collection = firestore.collection('timers')

  const {error, loading, value} = useDocument(collection.doc(timerId))

  useWakeLock(true) // only lock when timer is ticking

  if (error) {
    console.error(error)
    return <div>Something broke</div>
  }

  if (loading || value === undefined) return <Loading/>

  if (!value.exists) {
    throw new Error(`Timer ${timerId} not found`)
  }

  const data = value.data()
  if (data === undefined) {
    console.error(error)
    return <div>Something broke</div>
  }
  const target : firebase.firestore.Timestamp = data.target 
  const createdAt : firebase.firestore.Timestamp = data.createdAt
  const remaining : number = data.remaining

  const start = ()=> {
    const millis = remaining + Date.now()
    const newTarget = firebase.firestore.Timestamp.fromMillis(millis)
    value.ref.set({target: newTarget, createdAt} )
    const session = window.cast.framework.CastContext.getInstance().getCurrentSession()
    if(session) {
      session.sendMessage('urn:x-cast:com.hillshum.roomboom.foo', {type: 'START_TIMER'})
    }

  }
  const pause = ()=> {
    value.ref.set({remaining: target.toMillis() - Date.now(), createdAt})
  }

  const paused = remaining !== undefined


  return <div>
    {target && <DurationView onFinish={advanceRound} target={target} onPause={pause}/>}
    {paused && <>
      <TimeView remaining={remaining}/>
      <Button onClick={start} disabled={remaining === 0}>Start</Button>
    </>}
      
  </div>
}

export default Timer