import { createMuiTheme } from '@material-ui/core/styles'
import { blue, yellow } from '@material-ui/core/colors'

declare module '@material-ui/core/styles/createTypography' {
  interface Typography {
    monoFontFamily: string
  }
  interface TypographyOptions {
    monoFontFamily? : string
  }
}

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: yellow, 
    background: {
      default: '#378b2e',
      paper: '#fff',
    },
  },
  
  typography: {
    useNextVariants: true,
    monoFontFamily: [
      '"Roboto Mono"',
      'monospace',
    ].join(','),
  }
})

export default theme