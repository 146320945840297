import React from 'react'

import {GameInfo } from 'roomboom-content'
import { Paper, Typography, withStyles, 
  Table, TableRow, TableCell, TableBody, createStyles, WithStyles } from '@material-ui/core'


const styles = () => createStyles({
  paper: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  finished: {
    padding: '10px',
  },
  leftCell: {
    'padding-right': '20px',
  }
})

interface Props extends WithStyles {
  round: number,
  gameInfo: GameInfo
}


const RoundInfo = ({gameInfo, round, classes}: Props) => {
  const finished = round > gameInfo.length
  const currentRound = gameInfo[round - 1]
  return <Paper className={classes.paper}>
    {finished ? 
      <Typography className={classes.finished}>Game Over</Typography>
      : <Table>
        <TableBody>
          <TableRow>
            <TableCell className={classes.leftCell}><Typography>Round:</Typography></TableCell>
            <TableCell><Typography>{round} of {gameInfo.length}</Typography></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.leftCell}><Typography>Length:</Typography></TableCell>
            <TableCell><Typography>{currentRound.len} min</Typography></TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.leftCell}><Typography>Hostages Exchanged:</Typography></TableCell>
            <TableCell><Typography> {currentRound.hostages}</Typography></TableCell>
          </TableRow>
        </TableBody>
      </Table>
    }
  </Paper>
}

export default withStyles(styles)(RoundInfo)