
import React from 'react'

type ChangeFunction = (val?:string)=>void
interface ReturnValue extends Array<string|ChangeFunction>{0:string; 1:ChangeFunction}

const usePath = () => {

  const initialPath = window.location.pathname.substr(1)

  const [path, setPath] = React.useState(initialPath)

  const changePath = (path: string = '') =>{
    window.history.pushState({}, path, `/${path}`)
    setPath(path)
  }

  React.useEffect(
    ()=>{
      window.onpopstate = ()=>{
        setPath(window.location.pathname.substr(1))
      }
      return ()=> {window.onhashchange = null}
    },
    [],
  )

  const ret : ReturnValue = [path, changePath]

  return ret
}


export default usePath